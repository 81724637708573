
import { createApi } from '../configs/ajax'
import { baseURL, /* baseURL, */ path } from '../configs/config'


const predata = 'data';
const defaulturl = '/api/v1';

const option = { baseURL: baseURL }

export const getLawsListHomePage = createApi(`${defaulturl}/${predata}/getLawsListHomePage 
`, option) // 首页法律的文件
export const getStandardDocumentNationWide = createApi(`${defaulturl}/${predata}/getStandardDocumentNationWide
`, option) // 全国的标准文件或者导则
export const getStandardDocumentProvinces = createApi(`${defaulturl}/${predata}/getStandardDocumentProvinces
`, option) // 各个省份的标准文件或者导则
