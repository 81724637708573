
import axios from 'axios'
// import { hashHistory } from 'react-router'
import { timeout, baseURL } from './config'
import { message } from 'antd'
let baseConfig = {
  url: '/',
  method: 'post', // default
  baseURL: '',
  params: {
  },
  data: {
  },
  timeout: '',
  withCredentials: true, // default
  responseType: 'json', // default
  maxContentLength: 2000,
  validateStatus(status) {
    return status >= 200 && status < 300 // default
  },
}
baseConfig = { ...baseConfig, timeout: timeout, baseURL: baseURL }


export const oftenFetchByPost = (api, options) => {
  // 当api参数为createApi创建的返回值
  if (typeof api === 'function') return api
  return (...rest) => { 
    // 参数分析
    const data = rest[0] || {}
    return new Promise ((resolve,reject)=>{
      axios({
        ...baseConfig, ...options, url: api, data,
        headers: {
          'Content-Type': 'application/json',
         
        },
      })
        .then(response => response.data)
        .then((response) => {
          switch (response.code) {
            case 0: { 
              resolve(response);
              break 
            }
           
            default: {
              reject()
              message.warning(response.msg)
              break
            }
          }
        })
        .catch((e) => {
          console.log(e,'response')
          reject(e)
        })
    })
  }
}

// 创建发起api的启动器
export const createApi = function (api, options) {
  return oftenFetchByPost(`${api}`, options)
}

