// import { login } from "../src/apis/common";

// function App() {

//   return (
//     <div className="App">
//       <div className="header">
//         <img src={require('./image/newlogo.png').default} className="logo"></img>
//         <div className='header_button'>
//           <button className="header_but_right" onClick={handleGoBack}>政策法规查看</button>
//           <button className="header_but_right" onClick={handleGoBack}>政策法规查看</button>
//         </div>
//       </div>

//       <div className="content">
//         <div className="contentTainer">
//           <img src={require('./image/listimg.png').default} className="listimg"></img>
//           <div className="contentright">
//             <div className="btnlink">
//               <img src={require('./image/wangye.png').default} className="wangye"></img>
//               <a className="download" href="https://web.crisk.com.cn/#/login" target="_blank">进入系统</a>
//             </div>
//             <div className="myline"></div>
//             <div className="btnlink">
//               <img src={require('./image/kehuduan.png').default} className="wangye"></img>
//               <div className="downbutton">
//                 <a className="download" href="https://crisk.oss-cn-shanghai.aliyuncs.com/downloadPack/CRISK.exe">Windows</a>
//                 <a className="download" href="https://crisk.oss-cn-shanghai.aliyuncs.com/downloadPack/CRISK.dmg">MacOS</a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>


//       <footer className="footer">
//         <div className="tecnolige">
//           <div className="nanjing">
//             <span>技术支持:</span>
//             <img src={require('./image/yanjiu.png').default} className="nanjinglogo" />
//             <span>生态环境部南京环境科学研究所</span>
//           </div>
//           <div className="nanjing">
//             <img src={require('./image/qinghua.png').default} className="nanjinglogo" />
//             <span>清华苏州环境创新研究院</span>
//           </div>
//         </div>
//         <div className="yunying">
//           <span>运营单位：</span>
//           <img src={require('./image/dashi.png').default} className="dashilogo" />
//           <span>苏州大实环境科技有限公司</span>
//         </div>
//         <div className="link">
//           <div className="Copyright">
//             <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" className="targetlink">Copyright ©2021  苏ICP备202102011号-1</a>
//           </div>
//           <div className="Copyrightbeian">
//             <img src="https://www.gov.cn/govweb/xhtml/2016gov/images/index/2018guohui03.png" />
//             <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002003543" target="_blank" className="targetlink">苏公网安备 32059002003543号</a>
//           </div>
//         </div>
//         <div className="erweimaleft">
//           <img src={require('./image/ewm.jpeg').default} className="ewmimg"></img>
//           <div className="care">
//             关注CRISK公众号
//           </div>
//         </div>
//       </footer>
//     </div>
//   );
// }

// export default App;

import React from 'react';
import './App.css';
import { getLawsListHomePage,getStandardDocumentNationWide,getStandardDocumentProvinces } from '../src/apis/common'
import { Menu, Modal, Form, Col, Row, Dropdown, Space, message, Button, Radio, Input, Tabs, Table, Divider, Popconfirm } from "antd";
import {
  DownOutlined,
 
} from '@ant-design/icons';



const { SubMenu } = Menu;
const file = [
  {
    title: '序号',
    dataIndex: 'ID',
    key: 'ID',
    align: 'center',
    width:'70px'
  },
  {
    title: '标准导则文件',
    dataIndex: 'Title',
    key: 'Title',
    align: 'center'
  },
  {
    title: '标准编号',
    dataIndex: 'Identifier',
    key: 'Identifier',
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'URL',
    key: 'URL',
    align: 'center',
    render: (record) => {
      return <span>
        <a style={{ marginRight: 8 }} href={record} target="_blank">查看</a>
        <a href={record} download style={{ marginRight: 8 }} target="_blank">下载</a>
      </span>;
    }
  },
]
class App extends React.Component {
  constructor() {
    super();

    this.state = {
      columns: [],
      fileData: [],
      isScroll: null,
      isloading: true,
      title: '',
      rowSelection: null,
      placeholder: "请输入中英文名或CAS编号",
      searchfile: false,
      searchKey:'',
    }
  }

  getStandardDocuments = async () => {
    let res = await getLawsListHomePage();
    console.log("111111", res.data);

    this.setState({
      columns: file,
      fileData: res.data,
      isScroll: null,
      isloading: false,
      title: '标准导则文件查询',
      rowSelection: null,
      placeholder: ""
    })
    this.searchfileVisible(true)

  }
  searchfileVisible = (value) => {
    console.log("11111",value);
    this.setState({
      searchfile: value
    })
  }
  clearStatus = () => {
    this.setState({
      columns: [],
      fileData: [],
      isScroll: null,
      keyword: '',
      searchKey: '',
      isloading: true,
      title: '',
      rowSelection: null,
      placeholder: ''
    })
  }
  searchMethod = (key) => {
    console.log("key",key);
    this.clearStatus()
    switch (key) {
      case '1':
        this.getLawsListHomePage()
        break;
      case '2':
        this.getStandardDocumentNationWide()
        break;
      case '3':
        this.getStandardDocumentProvinces()
        break;
     
      default:
        break;
    }
    this.setState({
      searchKey: key
    })
    this.searchfileVisible(true)
  }
  getLawsListHomePage = async () => {
    console.log("1111111",);
    let res = await getLawsListHomePage();
    console.log("111111", res.data);

    this.setState({
      columns: file,
      fileData: res.data,
      isScroll: null,
      isloading: false,
      title: '法律文件',
      rowSelection: null,
      placeholder: ""
    })
    this.searchfileVisible(true)

  }
  getStandardDocumentNationWide = async () => {
    let res = await getStandardDocumentNationWide();
    console.log("111111", res.data);

    this.setState({
      columns: file,
      fileData: res.data,
      isScroll: null,
      isloading: false,
      title: '全国的标准文件或导则',
      rowSelection: null,
      placeholder: ""
    })
    this.searchfileVisible(true)

  }
  getStandardDocumentProvinces = async () => {
    let res = await getStandardDocumentProvinces();
    console.log("111111", res.data);

    this.setState({
      columns: file,
      fileData: res.data,
      isScroll: null,
      isloading: false,
      title: '各个省份的标准文件或导则',
      rowSelection: null,
      placeholder: ""
    })
    this.searchfileVisible(true)

  }
  render() {
    let dataBaseMenu = (
      <Menu>
       
          <Menu.Item onClick={() => this.searchMethod('1')}>法律文件</Menu.Item>
          <Menu.Item onClick={() => this.searchMethod('2')}>全国的标准文件或导则</Menu.Item>
          <Menu.Item onClick={() => this.searchMethod('3')}>各个省份的标准文件或导则</Menu.Item>
       
      </Menu>
    )
    return (
      <div>
        <div className="App">
          <div className="header">
            <img src={require('./image/newlogo.png').default} className="logo"></img>
            <div className='header_button'>
              {/* <button className="header_but_right" onClick={this.getStandardDocuments}>政策法规查看</button> */}
              <Dropdown overlay={dataBaseMenu} placement="bottomCenter" arrow overlayClassName='resetOverlay'>
                <div className="headbettwen">
                
                  <span className="headerColor">数据库查询</span>
                  {/* <img src={require("../src/image/qinghua.png").default}></img> */}
                  <DownOutlined  />
                </div>
              </Dropdown>
            </div>
            <Modal
              title={this.state.title}
              visible={this.state.searchfile}
              onOk={() => this.searchfileVisible(false)}
              onCancel={() => this.searchfileVisible(false)}
              cancelText="取消"
              okText="确定"
              width={1000}
              destroyOnClose={true}
            >
              
              <Table
                bordered
                columns={file}
                dataSource={this.state.fileData}
                pagination={{ pageSize: 8 }}
                size="small"
                scroll={this.state.isScroll}
                loading={this.state.isloading}
                rowKey={record => record.key || record.ID}
                rowSelection={this.state.rowSelection}
              />
            </Modal>
          </div>
          <div className="content">
            <div className="contentTainer">
              <img src={require('./image/listimg.png').default} className="listimg"></img>
              <div className="contentright">
                <div className="btnlink">
                  <img src={require('./image/wangye_web.png').default} className="wangye"></img>
                  
                  <p className='Link_p'>企业版</p>
                  <a className="download download_web" href="https://web.crisk.com.cn/#/login" target="_blank">进入系统</a>
                </div>
                <div className="myline"></div>
                <div className="btnlink">
                  <img src={require('./image/wangye_app.png').default} className="wangye"></img>
                  <p className='Link_p'>科研版</p>
          
                  <a className="download download_app" href="https://app.crisk.com.cn/#/login" target="_blank">进入系统</a>
                </div>
                {/* <div className="btnlink">
              <img src={require('./image/kehuduan.png').default} className="wangye"></img>
              <div className="downbutton">
                <a className="download" href="https://crisk.oss-cn-shanghai.aliyuncs.com/downloadPack/CRISK.exe">Windows</a>
                <a className="download" href="https://crisk.oss-cn-shanghai.aliyuncs.com/downloadPack/CRISK.dmg">MacOS</a>
              </div>
            </div> */}
              </div>
            </div>
          </div>
          {/* <div className="content">
            <div className="contentTainer">
              <img src={require('./image/listimg.png').default} className="listimg"></img>
              <div className="contentright">
                <div className="btnlink">
                  <img src={require('./image/wangye.png').default} className="wangye"></img>
                  <a className="download" href="https://web.crisk.com.cn/#/login" target="_blank">进入系统</a>
                </div>
                <div className="myline"></div>
                <div className="btnlink">
                  <img src={require('./image/kehuduan.png').default} className="wangye"></img>
                  <div className="downbutton">
                    <a className="download" href="https://crisk.oss-cn-shanghai.aliyuncs.com/downloadPack/CRISK.exe">Windows</a>
                    <a className="download" href="https://crisk.oss-cn-shanghai.aliyuncs.com/downloadPack/CRISK.dmg">MacOS</a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}


          <footer className="footer">
            <div className="tecnolige">
              <div className="nanjing">
                <span>技术支持:</span>
                <img src={require('./image/yanjiu.png').default} className="nanjinglogo" />
                <span>生态环境部南京环境科学研究所</span>
              </div>
              <div className="nanjing">
                <img src={require('./image/qinghua.png').default} className="nanjinglogo" />
                <span>清华苏州环境创新研究院</span>
              </div>
            </div>
            <div className="yunying">
              <span>运营单位：</span>
              <img src={require('./image/dashi.png').default} className="dashilogo" />
              <span>苏州大实环境科技有限公司</span>
            </div>
            <div className="link">
              <div className="Copyright">
                <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" className="targetlink">Copyright ©2021  苏ICP备202102011号-1</a>
              </div>
              <div className="Copyrightbeian">
                <img src="https://www.gov.cn/govweb/xhtml/2016gov/images/index/2018guohui03.png" />
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002003543" target="_blank" className="targetlink">苏公网安备 32059002003543号</a>
              </div>
            </div>
            <div className="erweimaleft">
              <img src={require('./image/ewm.jpeg').default} className="ewmimg"></img>
              <div className="care">
                关注CRISK公众号
              </div>
            </div>
          </footer>
        </div>

      </div>
    );
  }
}

export default App;
